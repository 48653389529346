import * as React from "react";
import { LoadingPage } from "../src/components/LoadingPage";

import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { AUTH0_SCOPE } from "../src/constants/env";
import router from "next/router";

const LoginPage = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({
        scope: AUTH0_SCOPE,
        redirectUri: window.location.origin + "/redirect?authType=auth0"
      });
    }
    if (isAuthenticated && !isLoading) {
      router.push({
        pathname: "/redirect"
      });
    }
  }, [typeof window, isAuthenticated, isLoading]);
  return <LoadingPage />;
};

export default LoginPage;
