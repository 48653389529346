import { Layout } from "../src/components/Layout";
import LoginPage from "./login";

const IndexPage = () => (
  <Layout>
    <LoginPage />
  </Layout>
);

export default IndexPage;
