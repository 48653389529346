import { Flex } from "@papercup-ai/orizuru";
import Head from "next/head";
import * as React from "react";

export const Layout = ({ children }: { children: React.ReactNode }) => (
  <Flex
    id={"layout"}
    width={"100vw"}
    height={"100vh"}
    justifyContent={"center"}
    alignItems={"center"}
    flexDirection={"column"}
  >
    <Head>
      <title>Origami</title>
      <meta
        name={"viewport"}
        content={"width=device-width, initial-scale=1, shrink-to-fit=no"}
      />
    </Head>
    <main>{children}</main>
  </Flex>
);
