import { Flex, Typography } from "@papercup-ai/orizuru";

export const LoadingPage = (props: any) => (
  <Flex
    width={"100%"}
    justifyContent={"center"}
    alignItems={"center"}
    sx={{
      height: "100vh"
    }}
    {...props}
  >
    <Typography variant={"heading1"}>Loading...</Typography>
  </Flex>
);
